.gallery {
  // background: #f00;
  .image-thumbnails {
    width: 96px;
    height: 96px;
    margin: 10px;
    // border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    img.img-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #333;
      padding: 0;
      border:1px solid #000;
    }
  }
  .image-thumbnails:hover {
    img.img-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #333;
      padding: 0;
      border:2px solid #fff;
      filter: opacity(0.5);
    }
  }
}

// .gallery-modal {
//   display: none;
//   .modal-dialog {
//     background: #f0f0f0;
//   }
// }
.modal-content {
  background: #888 !important;
  width: 100%; /* 最大横幅 */
  height: 400px; /* 最大縦幅 */
  display: flex; /* 子要素をフレックスボックスで配置 */
  justify-content: center; /* 横方向の中央揃え */
  // align-items: center; /* 縦方向の中央揃え */
  background-color: #f0f0f0; /* 背景色を設定（任意） */
  overflow: hidden; /* 必要に応じてコンテナ外を非表示にする */
  .modal-header {
    display: flex; /* Flexboxを適用 */
    justify-content: flex-end !important; /* ボタンを右寄せ */
    align-items: center; /* ボタンを垂直方向で中央揃え */
    padding: 1rem; /* 適切な余白を追加 */
    border-bottom: none; /* 必要ならボーダーを削除 */
    width: 100%;
    .gallery-close-button {
      margin-top: 20px;
      // background: #f00;
      button {
        // background: #fff;
        color: #fff !important;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
        margin: 10px;
        float: right;
        z-index: 10000;
      }
    }
  }
  .modal-body {
    img {
      // display: none;
      max-width: 100%; /* 横幅をコンテナの幅以内に制限 */
      max-height: 100%; /* 縦幅をコンテナの高さ以内に制限 */
      width: auto; /* 縦横比を保つため自動計算 */
      height: auto; /* 縦横比を保つため自動計算 */
      display: block; /* 余分な余白を防ぐ */
      object-fit: contain; /* コンテナ内に画像を完全に収める */
      pointer-events: none;
    }
  }
}