.about {
  .about-images-inner {
    // background: #f00;
    img {
      filter: grayscale(80%);
      @media (max-width: 575px) {
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
      }
      @media (min-width: 576px) and (max-width: 768px) {
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        width: 190px;
        height: 152px;
        object-fit: cover;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        width: 152px;
        height: 152px;
        object-fit: cover;
      }
      @media (min-width: 1200px) and (max-width: 1399px) { 
        width: 212px;
        height: 212px;
        object-fit: cover;
      }
      @media (min-width: 1400px) {
        width: 212px;
        height: 212px;
        object-fit: cover;
      }
    }
    img:hover {
      filter: grayscale(0%);
    }
  }
  // background: #f00;
  .about-images {
    .about-image {
      filter: grayscale(80%);
      img {
        @media (max-width: 575px) {
          width: 100%;
          object-fit: cover;
          margin-bottom: 20px;
        }
        @media (min-width: 576px) and (max-width: 768px) {
          width: 100%;
          object-fit: cover;
          margin-bottom: 20px;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          width: 190px;
          height: 152px;
          object-fit: cover;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          width: 152px;
          height: 152px;
          object-fit: cover;
        }
        @media (min-width: 1200px) and (max-width: 1399px) { 
          width: 212px;
          height: 212px;
          object-fit: cover;
        }
        @media (min-width: 1400px) {
          width: 212px;
          height: 212px;
          object-fit: cover;
        }
      }
    }
    .about-image:hover {
      filter: grayscale(0%);
    }
  }
  .about-item {
    // background: #f00;
    margin: 20px 20px;
    h3.about-item-title {
      background: #444;
      padding: 10px 20px;
      border-radius: 10px;
      img.flower {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
    .about-item-text {
      padding: 20px 20px;
      a {
        color: #4682b4;
        text-decoration: none;
      }
      a:hover {
        color: #fff;
        text-decoration: underline;
      }
      ul {
        margin: 20px 0px;
        li {
          list-style: circle;
        }
      }
    }
  }
}