.footer {
  background: #8e4845;
  color: #ffe6e1;
  font-size: 12px;
  padding: 7px 0;
  font-weight: normal;
  overflow: hidden;
  margin-bottom: 0;
  box-shadow: 0px 0px 5px #444;
  z-index: 10;

  &.visible {
    opacity: 1;
    transform: translateY(0); /* 通常の表示位置 */
  }

  &.hidden {
    opacity: 0;
    transform: translateY(100%); /* 下に隠れる */
    pointer-events: none; /* 非表示時のクリックを無効化 */
  }

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .footer-pc {
    display: none; /* SPではPC用フッターを非表示 */
  }

  .footer-sp {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #8e4845;

    ul {
      text-align: center;
      margin: 0;
      padding: 20px 0;

      li {
        display: inline-block;
        margin: 0 10px;

        a {
          color: #ffe6e1;
          text-decoration: none;

          &:hover {
            color: #ffd1cc;
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .footer-pc {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: #8e4845;
    color: #c1c1c1;

    .footerbar {
      width: 100%;
      // background: #4f2725;ｓ
      height: 1px;
    }

    .footer-section {
      .footer-section-item {
        ul {
          margin-top: 10px;
          display: inline-block;

          li {
            margin-right: 20px;
            display: inline-block;

            a {
              color: #c1c1c1;
              text-decoration: none;

              &:hover {
                color: #aaa;
              }
            }
          }
        }
      }

      .copyright {
        text-align: center;
        padding: 0px 10px;
      }
    }
  }

  .footer-sp {
    display: none; /* PCではSP用フッターを非表示 */
  }
}

.footer-bar {
  height: 5px;
  background: #4f2725;
}
