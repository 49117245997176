.schedule {
  // background: #f00;
  .top-article {
    background: #333232;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    h3 {
      font-size: 40px;
      .wday {
        font-size: 14px !important;
      }
      .year {
        border: 1px solid #ccc;
        font-size: 14px !important;
        background: #000;
        padding: 1px 5px;
        border-radius: 2px;
      }
    }
    .top-article-title {
      font-size: 20px;
      margin-bottom: 10px;
      a {
        color: #fff;
        text-decoration: none;
      }
      a:hover {
        color: #4682b4;
      }
    }
    .top-article-text {
      color: #ccc;
    }
    .top-article-content {
      // background: #f00;
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }
  }
  .ad-area {
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}