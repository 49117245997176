// @media (max-width: 575px) { background: #880000; }
// @media (min-width: 576px) and (max-width: 768px) { background: #c0c0c0; }
// @media (min-width: 769px) and (max-width: 992px) { background: #808080; }
// @media (min-width: 993px) and (max-width: 1200px) { background: #4682b4; }
// @media (min-width: 1200px) and (max-width: 1399px) { background: #880000; }
// @media (min-width: 1400px) { background: #000; }

.staffs {
	// background: #f00;
  a {
    text-decoration: none;
    color: #4682b4;
  }
	.staff-block {
		// background: #0f0;
    margin-bottom: 30px;
		.staff-card {
			background: #c1c1c1;
			border: 1px solid #000;
      .card-body {
        .card-text {
          // background: #0f0;
          h4 {
            .owner {
              margin-left: 10px;
              font-size: 14px;
              color: #666;
            }
            .staff {
              margin-left: 10px;
              font-size: 14px;
              color: #666;
            }
          }
        }
      }
			img {
				filter: grayscale(50%);
        @media (max-width: 575px) {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
        @media (min-width: 576px) and (max-width: 768px) {
          width: 516px;
          width: 516px;
          object-fit: cover;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          width: 336px;
          height: 336px;
          object-fit: cover;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          width: 295px;
          height: 420px;
          object-fit: cover;
        }
        @media (min-width: 1200px) and (max-width: 1399px) {
          width: 355px;
          height: 400px;
          object-fit: cover;
        }
        @media (min-width: 1400px) {
          width: 415px;
          height: 400px;
          object-fit: cover;
        }
			}
		}
	}
}