.main-vidual {
  height: 700px;
  .slide {
    position: absolute;
    top: 53px;
    left: 0;
    height: 700px;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    z-index: 1;
    // inset: 0;
    // opacity: 0;
    transition: opacity 4s ease-in-out; /* フェードのアニメーションを1秒に設定 */
    img {
      width: 120%;
      height: 700px;
      object-fit: cover;
      // filter: grayscale(80%);
    }
  }
  .opacity-100 {
    opacity: 1;
  }
  .opacity-0 {
    opacity: 0;
  }
  .main-visual-overlay {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #00000080;
    color: #fff;
    z-index: 10;

    .main-visual-bigcopy {
      letter-spacing: 2px;
      @media (max-width: 992px) {
        .main-visual-title {
          font-size: 450%;
          word-break: break-all;
          padding: 10px;
          line-height: 61px;
          // text-align: center;
        }
        .main-visual-subtitle {
          font-size: 200%;
          word-break: break-all;
          padding: 10px;
          line-height: 61px;
          text-align: center;
        }
        .flex-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        .main-visual-title {
          font-size: 80px;
          padding: 20px;
          text-align: center;
        }
        .main-visual-subtitle {
          font-size: 30px;
          text-align: center;
        }
        .flex-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @media (min-width: 1200px) {
        .main-visual-title {
          font-size: 80px;
          padding: 20px;
          text-align: center;
        }
        .main-visual-subtitle {
          font-size: 30px;
          text-align: center;
        }
        .flex-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    

  }
}

.index-area {
  letter-spacing: 1px;;
  color: #ccc;
  margin-top: 100px;
  .right-side {
    // background: #ff0;
    font-size: 18px;
    ul {
      li {
        list-style: none;
        margin-bottom: 7px;
        border-left: 7px solid #432377;
        padding: 5px;
        margin-bottom: 7px;
        a {
          margin-left: 5px;
          color: #ccc;
          text-decoration: none;
        }
        a:hover {
          color: #fff;
        }
      }
    }
  }
  .content-column-b, .content-column-c {
    a {
      color: #4682b4;
    }
    h2 {
      border-left: 7px solid #432377;
      padding-left: 10px;
    }
    
    .top-article {
      background: #333232;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 20px;
      h3 {
        font-size: 40px;
        color: #fff;
        .wday {
          font-size: 14px !important;
        }
        .year {
          border: 1px solid #ccc;
          font-size: 14px !important;
          background: #000;
          padding: 1px 5px;
          border-radius: 2px;
        }
      }
      .top-article-title {
        font-size: 20px;
        margin-bottom: 10px;
        a {
          color: #fff;
          text-decoration: none;
        }
        a:hover {
          color: #4682b4;
        }
      }
      .top-article-text {
        color: #ccc;
      }
      .top-article-content {
        // background: #f00;
        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    .archive-link {
      // background: #ff0;
      text-align:  right;
      a {
        color: #4682b4;
        text-decoration: none;
      }
    }
  }
}
.second-area {
  margin-top: 50px;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
  }
  .shigog {
    margin-top: 50px;
    height: 200px;
    background: #000;
    border-radius: 5px;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: grayscale(90%) brightness(0.8);

    @media (max-width: 575px) { 
      height: 400px;
     }
    @media (min-width: 576px) and (max-width: 768px) {
      height: 500px;
     }
    @media (min-width: 769px) and (max-width: 992px) { 
      margin: 10px;
      margin-top: 50px;
      height: 200px;
     }
    @media (min-width: 993px) and (max-width: 1200px) {
      margin: 30px;
      margin-top: 50px;
      height: 260px;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      margin: 30px;
      margin-top: 50px;
      height: 300px;
    }
    @media (min-width: 1400px) {
      margin: 50px;
      margin-top: 50px;
      height: 300px;
    }
  }
  /**
    * 個別の設定
    */
  .mitsu {
    // background: #ff0 !important;
    .shigog-pre-title {
      color: #eee;
      letter-spacing: 1px;
      opacity: 0.7;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 23px;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        // display: none;
      }
      @media (min-width: 993px) {
        font-size: 20px;
      }
    }
    .shigog-title {
      @media (max-width: 768px) {
        font-size: 60px;
        background: #000;
        opacity: 0.7;
        text-align: center;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        font-size: 70px;
        background: #000;
        opacity: 0.7;
        text-align: center;
      }
      @media (min-width: 993px) {
        font-size: 50px;
        background: #000;
        opacity: 0.7;
        text-align: center;
      }
    }
    .shigog-summary {
      @media (max-width: 768px) {
        font-size: 20px;
        color: #c1c1c1;
        padding: 30px;
      }
      @media (min-width: 769px) and (max-width: 992px) {
        display: none;
      }
      @media (min-width: 993px) {
        font-size: 13px;
        color: #c1c1c1;
      }
    }
  }
}

.third-area {
  // background: #888;
  margin-top: 50px;
  margin-bottom: 50px;
  a:link, a:visited {
    color: #4682b4;
    text-decoration: none;
  }
  a:hover, a:active {
    color: #5aa7e6;
    text-decoration: underline;
  }
  .info-title {
    margin-left: 30px;
    border-left: 7px solid #432377;
    padding-left: 10px;
  }
  .information {
    // background: #888;
    margin-left: 50px;
    padding: 20px;
    color: #ddd;
    li {
      list-style: circle;
      a:link, a:visited {
        color: #4682b4;
        text-decoration: none;
      }
      a:hover, a:active {
        color: #5aa7e6;
        text-decoration: underline;
      }
    }
  }
}

.confirmation18-modal {
  .modal-content {
    background: #444;
    .modal-body {
      height: 150px;
      flex: none;
      font-size: 18px;
    }
    .modal-footer {
      border: none;
      .shigonawa-btn-primary {
        background: #000;
        border: 1px solid #432377;
        color: #fff;
        &:hover {
          background: #fff;
          color: #432377;
        }

      }
      .shigonawa-btn-secondary {
        background: #333;
        border: 1px solid #888;
        color: #fff;
        &:hover {
          background: #fff;
          color: #333;
        }
      }
    }
  }
}

.fading-text-1, .fading-text-2 {
  margin-left: 30px;
  margin-right: 30px;
  line-height: 35px;
}
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}