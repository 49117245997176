// #4f2725
// #4f2725
// #8e4845
// #432377 紫(濃)

.topbar {
  position: fixed;
  top: 0;
  left: auto;
  width: 100%;
  // background: #4f2725 !important;
  height: 5px;
  z-index: 1000000;
  @media (max-width: 575px) { background: #4f2725; }
  @media (min-width: 576px) and (max-width: 768px) { background: #666; }
  @media (min-width: 769px) and (max-width: 992px) { background: #000000; }
  @media (min-width: 993px) and (max-width: 1200px) { background: #333333; }
  @media (min-width: 1200px) and (max-width: 1399px) { background: #8e4845; }
  @media (min-width: 1400px) { background: #4f2725; }
}

.navi {
  margin-top: 5px;
  background-color:#8e4845;
  color: #ffe6e1;
  font-size: 12px;
  padding: 7px 0;
  font-weight: normal;
  overflow: hidden;
  margin-bottom: 0;
  box-shadow: 0px 0px 5px #444;
  z-index: 10;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
  opacity: 0.9;
  .navi-menu {
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .navi-logo {
    width: 30px;
    height: 30px;
    // border-radius: 50%;
    margin-right: 10px;
  }
  .navi-title {
    font-size: 18px;
    // font-weight: bold;
    
  }
  .navi-list {
    display: inline-block;
    margin: 0;
    padding: 0;
    @media (max-width: 575px) { display: none; }
    @media (min-width: 576px) {
      display: inline-block;
      margin-left: 20px;
    }
    list-style: none;
    li {
      display: inline-block;
      margin-right: 10px;
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: #888;
        }
      }
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* サイドバーが非表示の時の位置 */
  width: 300px;
  height: 100%;
  background-color: #000;
  color: white;
  transition: right 0.3s ease; /* 開閉のアニメーション */
  padding: 20px;
  z-index: 10000000; /* 他の要素の上に表示させる */
  border-left: 5px solid #4f2725;
  overflow-y: auto;
}

.sidebar.open {
  right: 0; /* サイドバーが表示される時の位置 */
}

.close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
}

.close-btn:hover {
  color: #ccc; /* ホバー時の色変更 */
}

.sidebar-content {
  // background: #ff0;
  .sidebar-title {
    margin-left: 10px;
  }
  ul.sidebar-list {
    margin-top: 30px;
    margin-left: -15px;
    li {
      list-style: none;
      font-size: 23px;
      margin-bottom: 10px;
      a:link, a:visited {
        color: #eee;
        text-decoration: none;
      }
      a:hover, a:active {
        color: #888;
      }
    }
  }
}
.sns-icons {
  border-top: 1px solid #666;
  padding-top: 30px;

  .sns-icon {
    display: inline-block;
    a {
      color: #fff;
    }
    margin-top: 15px;
    margin-right: 15px;
  }

  // background: #f00;

}

