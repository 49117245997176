.contact {
  .contact-area {
    input {
      background: #aaa;
      border: 1px solid #333;
    }
    textarea {
      background: #aaa;
      border: 1px solid #333;
    }
    .shigonawa-btn {
      background: #000;
      border: 1px solid #000;
    }
  }
  // background: #f00;
  .top-article {
    background: #333232;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    h3 {
      font-size: 40px;
      .wday {
        font-size: 14px !important;
      }
    }
    .top-article-title {
      font-size: 20px;
      margin-bottom: 10px;
      a {
        color: #fff;
        text-decoration: none;
      }
      a:hover {
        color: #4682b4;
      }
    }
    .top-article-text {
      color: #ccc;
    }
  }
  .ad-area {
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}